// OrderTableRow.scss
.order-row {
  cursor: pointer;

  td {
    padding: 2px;

    .order-status-chip {
      background-color: rgb(96, 96, 96);
      color: white;
      padding: 3px 6px;
      border-radius: 10px;
      margin: 3px;
      max-width: 90px;
      text-align: center;
      font-weight: 600;
      font-size: 10px;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .archived {
      background-color: rgb(54, 219, 84);
      color: rgb(233, 255, 239);
      font-weight: 700;
    }

    .inprogress {
      background-color: rgb(206, 206, 206);
      color: rgb(41, 41, 41);
    }

    .cancelled {
      background-color: rgb(221, 104, 104);
      color: rgb(255, 255, 255);
    }
  }
}

.expanded-row {
  background-color: #ffffff; // White
}

.order-details {
  padding: 1rem;
  display: flex;
  flex-direction: column;

  .order-summary {
    h4 {
      margin-top: 0px;
    }
  }

  .order-details-columns {
    display: flex;

    .order-items {
      flex: 1;
      padding: 0px 5px;
      border-width: 1px;
      border-color: #00000054;
      border-radius: 10px;
      border-style: dashed;
      background-color: white;

      ul {
        list-style: none;
        padding: 0;

        .list-price {
          font-weight: bold;
        }

        .total-row {
          font-weight: bold;
          margin-top: 0.5rem;
        }
      }

      .items-table {
        border-collapse: collapse;

        th,
        td {
          border: none;
          text-align: left;
        }

        .item-title {
          width: 80%;
          padding: 0.25rem 0;
        }

        .item-price {
          width: 20%;
          padding: 0.25rem 0;
          text-align: right;
          padding-right: 15px;
        }

        .total-row {
          td {
            font-weight: 700;
          }
        }
      }
    }

    .order-platform {
      background-color: white;
      flex: 1;
      max-width: 200px;
      margin-left: 1rem;

      padding: 0px 5px;
      border-width: 1px;
      border-color: #00000054;
      border-radius: 10px;
      border-style: dashed;

      text-align: center;
    }
  }
}
