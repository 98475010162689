.category-icon-select-wrapper {
  .icon-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    img {
      width: 42px;
      margin: 3px;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
    }

    .selected {
      background-color: #fb867d;
      border-radius: 6px;

      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(96deg) brightness(105%) contrast(104%);
      }
    }
  }

  .show-icons-btn {
    margin-bottom: 5px;
  }
}
