.order-status-preview-container {
  position: relative;
  $original-width: 1920px;
  $original-height: 1080px;
  $iframe-scale: 0.25;

  border: 10px solid #cbcbcb;
  border-radius: 5px;

  iframe {
    transform: scale($iframe-scale, $iframe-scale) translate(-50%);
    transform-origin: top left;
    position: absolute;
    left: 50%;
  }

  .iframe-placeholder {
    width: $original-width * $iframe-scale;
    height: $original-height * $iframe-scale;
  }

  .iframe-load {
    width: $original-width * $iframe-scale;
    height: $original-height * $iframe-scale;
    position: absolute;
    top: 0;
    background-color: #000000ac;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
