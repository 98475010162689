@import './styles/variables.scss';

#root {
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll !important;
}

main {
  // width: 100vw;
  // min-height: 100vh;
  // overflow: hidden;
}

header,
// main,
footer {
  padding-left: 300px;
}

@media only screen and (max-width: 992px) {
  header,
  main,
  footer {
    padding-left: 0;
  }
}

@media only screen and (min-width: 993px) {
  .container {
    width: 85% !important;
  }
}

.white-box {
  padding: 15px;
  border-radius: 15px;
  background-color: #fff;
}

h3 {
  font-weight: 600 !important;
  font-size: 2rem !important;
}

h4 {
  font-weight: 600 !important;
  font-size: 1.5rem !important;
}

h5 {
  font-weight: 600 !important;
  font-size: 1.1rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.very-thin {
  font-weight: 200 !important;
}

.thin {
  font-weight: 300 !important;
}

.max-w-450 {
  max-width: 450px;
}

.max-w-500 {
  max-width: 500px;
}

.tab {
  margin-left: 0.75rem;
}

.noscroll {
  overflow: hidden !important;
}

.silent {
  opacity: 0.4;
}

.pepi-card {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 12px 12px 24px hsl(0deg 0% 5% / 7%);
  height: 100%;

  .pepi-card-title {
    background-color: #f9f9ff;
    padding: 20px;
    border-radius: 16px 16px 0px 0px;

    h3 {
      margin-top: 0px;
    }
  }

  .pepi-card-content {
    padding: 30px;

    h3 {
      margin-top: 0px;
    }
  }
}

.auth-bg {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  min-height: 80vh;
}

.pepi-form {
  input {
    border-radius: 5px;
    border: 1px solid #111;
    width: 100%;
    padding: 10px;
    margin-top: 11px;
    margin-bottom: 11px;
  }

  .restaurant-image {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 15px;
  }

  .opening-times-row {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    justify-content: space-between;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 1);

    .digits {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .address-box {
    .address {
      margin: 0;
    }

    .zip-code {
      margin: 0;
      font-size: 1rem;
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .edit-field {
    visibility: hidden;

    .btn-small {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .closed {
    padding: 5px 15px;
    border-radius: 15px;
    background-color: #fe3333;
    color: white;
    font-weight: 600;
  }
}

.restaurant-info-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.03);

  .edit-field {
    visibility: visible;
  }
}

.error-wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: rgba(255, 0, 0, 0.1);
  border-radius: 20px;

  .error-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .error-txt {
    font-weight: 600;
  }
}

.bold {
  font-weight: 600;
}

.loading-wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 20px;

  .loading-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.editable-text {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;

  .row-buttons {
    justify-content: flex-end;
    display: flex;

    button {
      margin-left: 15px;
    }
  }

  .error {
    color: rgba(255, 0, 0, 0.6);
    font-size: 0.9rem;
    font-style: italic;
  }
}

.editable-text-not-editing {
  padding: 20px;

  .row-buttons {
    visibility: hidden;
    flex-direction: row;
    justify-content: flex-end;

    button {
      margin-left: 15px;
    }

    p {
      margin-right: auto;
      font-weight: 700;
    }
  }

  &.list-view {
    padding: 5px;

    .description {
      display: none;
    }

    .row-buttons {
      visibility: visible;
      display: flex;

      .btn-small {
        line-height: 20px;
        height: 20px;
      }
    }
  }
}

.editable-text-not-editing:hover {
  .row-buttons {
    visibility: visible;
    display: flex;
  }
}

.modal-small {
  max-width: 400px;
}

.mynt-modal {
  width: 70% !important;
  max-width: 850px;

  .image-preview {
    width: 250px;
    height: 150px;
    object-fit: cover;
    border-radius: 15px;
  }

  .error-msg {
    color: red;
    background-color: rgba(255, 0, 0, 0.08);
    padding: 10px;
    border-radius: 10px;
    text-transform: capitalize;
  }

  .modal-banner {
    height: 100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;

    .btn-floating {
      margin: 20px;
    }

    .change-image {
      visibility: hidden;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.594);

      form {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .input-field {
          margin: 0px;

          .upload-btn {
            background-color: #232185;
            color: #fff;
            width: 100%;
            padding: 10px;

            font-size: 1.2rem;
            border: none;
            border-radius: 15px;
            margin-top: 11px;
            margin-bottom: 11px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .modal-edit-item {
    .edit-options-list {
      .option-field {
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        margin: 15px 5px;
      }
    }

    .image-preview {
      width: 250px;
      height: 150px;
      object-fit: cover;
      border-radius: 15px;
    }

    .tabs {
      border-radius: 5px;

      .indicator {
        background-color: #232185;
      }

      .tab {
        a {
          color: #232185;
        }

        a:focus.active {
          background-color: rgb(35 33 133 / 10%);
        }
      }
    }
  }

  .modal-banner:hover {
    .change-image {
      visibility: visible;
    }
  }

  .selection-wrapper {
    width: 100%;

    .item-option {
      width: 100%;

      span {
        width: 100%;
        margin-top: 5px;
        margin-bottom: 5px;

        strong {
          color: #232185;
        }
      }
    }
  }

  .modal-footer {
    border-top: 1px;
    border-bottom: 0px;
    border-right: 0px;
    border-left: 0px;

    border-color: rgb(198, 198, 198);
    border-style: solid;
  }

  .edit-box {
    padding: 15px;

    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 15px;
    margin-top: 15px;
    margin-bottom: 15px;

    input {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .row {
      margin: 10px 0px;
    }

    .image-container {
      width: 250px;

      .remove-img {
        display: none;
        position: absolute;
        top: 4px;
        left: 215px;
        color: white;
        padding: 5px;
        width: 30px;
        height: 30px;
        background-color: rgba(255, 255, 255, 0.412);
        border-radius: 50%;
        text-align: center;

        line-height: 16px;
        font-size: 18px;
        font-weight: 700;
      }

      &:hover {
        .remove-img {
          display: block;
        }
      }
    }
  }

  .time-form {
    max-width: 420px;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 15px;
    padding: 15px;

    table {
      td {
        input {
          padding: 3px;
          border-width: 1px;
          border-style: solid;
          border-radius: 5px;
          height: 1.75rem;
          margin: 0px;
          width: 80px;
          text-align: center;
        }

        padding: 10px;
      }
    }
  }
}

.order-info-row {
  display: flex;
  margin-bottom: 5px;
  justify-content: space-between;

  .toggleable-menu {
    margin-right: 5px;
  }

  .search {
    border: 5px solid #42a5f5;
    margin-top: 10px;
  }
}

.team-table {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-width: 1px;
  border-style: solid;
  border-color: 1px solid rgba(0, 0, 0, 0.12);

  tr:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }

  .user-details {
    display: flex;
    flex-direction: column;

    .name {
      width: 100%;
    }

    .phone {
      font-size: 10px;
    }
  }
}

.orders-table {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-width: 1px;
  border-style: solid;
  border-color: 1px solid rgba(0, 0, 0, 0.12);

  th {
    padding: 5px;
  }

  tr:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }

  .locations {
    i {
      margin-top: 5px;
    }
  }

  .order-id {
    font-size: 12px;
    text-transform: uppercase;
  }

  .customer-details {
    display: flex;
    flex-direction: column;

    .name {
      width: 100%;
    }

    .phone {
      font-size: 10px;
    }
  }

  .order-chip {
    font-size: 12px !important;
    font-weight: 600 !important;
    margin-right: 4px;

    i {
    }
  }
}

.image-preview {
  max-width: 200px;
}

.hover-bg:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.pepi-button {
  background-color: #fb867d;
  color: #fff;
  width: 100%;
  padding: 10px;

  font-size: 1.2rem;
  border: none;
  border-radius: 15px;
  margin-top: 11px;
  margin-bottom: 11px;
  cursor: pointer;
}

// button:hover {
//   background-color: #2f2dad;
// }

// button:focus {
//   background-color: #232185;
// }

.scroll-to-bottom {
  padding: 15px;
  border-radius: 10px;
  position: fixed;
  bottom: 55px;

  h4 {
    margin-bottom: 0px;
    margin-top: 5px;
  }

  p {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  button {
    background-color: rgba(0, 0, 0, 0.05);
    color: #000;
    width: 100%;
    padding: 10px;

    font-size: 1.2rem;
    border: none;
    border-radius: 15px;
    margin-top: 6px;
    margin-bottom: 0px;
    cursor: pointer;
  }

  button:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-style: solid;
    border-width: 2px;
    margin-left: -2px;
    margin-bottom: -2px;
  }

  button:focus {
    background-color: #e4e4e4;
  }
}

.login-wrapper {
  max-width: 400px;
}

.notification {
  background-color: rgb(205, 255, 209);
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 10px;
  margin-bottom: 10px;

  .material-icons {
    margin-right: 10px;
  }
}

.auth-header {
  padding: 10px;

  .logo {
    max-width: 100px;
  }
}

.sidenav {
  ul {
    margin: 0px;
  }

  .sidenav-banner {
    background: rgb(85, 91, 95);
    background: linear-gradient(0deg, rgba(85, 91, 95, 1) 0%, rgba(46, 54, 64, 1) 100%);
    min-height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      max-height: 150px;
    }
  }

  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.hidden {
  .item-row {
    opacity: 0.34;
  }
}

.dragging {
  background-color: rgba(223, 223, 223, 0.882);
  border-radius: 10px;
}

.menu-item-wrapper {
  $border-radius: 4px;
  margin-right: 20px;

  &.editing .menu-item {
    pointer-events: none;
  }

  &.editing {
    margin-right: 0;
    border: $border-radius dashed $mynt-main;
    border-radius: 5px 0 0 5px;
    border-right: none;
    position: relative;

    &::after {
      position: absolute;
      right: -$border-radius * 2;
      top: 0;
      content: '';
      height: calc(100%);
      width: $border-radius * 2;
      background-color: #fff;
      z-index: 11;
    }
  }
}

.menu-item {
  padding: 20px;

  .item-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .item-row-buttons {
    margin-top: 10px;
    visibility: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      margin-left: 15px;
    }
  }

  .item-info {
    .price {
      color: #232185;
      font-weight: 500;
    }

    .eanCode {
      color: #414141;
      font-size: 12px;
    }

    .hidden-item {
      color: red;
    }

    h5 {
      span {
        color: red;

        &.special-override {
          color: orange;
        }

        &.extras {
          font-weight: 300;
          font-size: 10px;
          color: #939393;
        }
      }
    }

    width: 66.666%;
    padding-right: 10px;
  }

  .item-image {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    height: 130px;
    width: 33.333%;
    border-radius: 15px;
  }

  border-style: solid;
  border-width: 1px;
  border-color: white;

  &.selected {
    border-style: solid;
    border-width: 1px;
    border-color: #fb867d !important;
    border-style: dashed !important;
    margin-top: 5px;
  }

  &.list-view {
    padding: 5px;

    .item-row {
      .item-info {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .description {
          display: none;
        }
      }

      .item-image {
        display: none;
      }
    }

    .btn-small {
      line-height: 20px;
      height: 20px;
    }

    .item-row-buttons {
      visibility: visible;
      justify-content: space-around;
    }
  }
}

.menu-item-staff {
  max-width: 500px;
  padding: 20px;

  .item-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .item-row-buttons-staff {
    .btn-flat {
      width: 100%;
    }

    .buttons {
      .btn {
        width: 100%;
        margin-top: 5px;
      }
    }
  }

  .item-info {
    .price {
      color: #232185;
      font-weight: 500;
    }

    width: 66.666%;
    padding-right: 10px;
  }

  .item-image {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    height: 130px;
    width: 33.333%;
    border-radius: 15px;
  }

  border-style: solid;
  border-width: 1px;
  border-color: white;

  &.selected {
    border-style: solid;
    border-width: 1px;
    border-color: #fb867d !important;
    border-style: dashed !important;
    margin-top: 5px;
  }
}

.menu-item:hover {
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgb(230, 230, 230);
  box-shadow: 12px 12px 24px hsl(0deg 0% 5% / 7%);
  background: rgb(248, 248, 248);
  background: linear-gradient(0deg, rgba(248, 248, 248, 1) 0%, rgba(255, 255, 255, 1) 100%);
  position: relative;
  z-index: 5;

  .item-row-buttons {
    visibility: visible;
  }
}

.restaurant-info-wrapper {
  border-radius: 10px;
  padding: 10px;

  .restaurant-image {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 15px;
  }

  .opening-times-row {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    justify-content: space-between;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 1);

    .digits {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .address-box {
    .address {
      margin: 0;
    }

    .zip-code {
      margin: 0;
      font-size: 1rem;
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .edit-field {
    visibility: hidden;

    .btn-small {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .closed {
    padding: 5px 15px;
    border-radius: 15px;
    background-color: #fe3333;
    color: white;
    font-weight: 600;
  }
}

.restaurant-info-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.03);

  .edit-field {
    visibility: visible;
  }
}

.error-wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: rgba(255, 0, 0, 0.1);
  border-radius: 20px;

  .error-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.bold {
  font-weight: 600;
}

.loading-wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 20px;

  .loading-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.editable-text {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;

  .row-buttons {
    justify-content: flex-end;
    display: flex;

    button {
      margin-left: 15px;
    }
  }

  .error {
    color: rgba(255, 0, 0, 0.6);
    font-size: 0.9rem;
    font-style: italic;
  }
}

.editable-text-not-editing {
  padding: 20px;

  .row-buttons {
    visibility: hidden;
    flex-direction: row;
    justify-content: flex-end;

    button {
      margin-left: 15px;
    }

    p {
      margin-right: auto;
      font-weight: 700;
    }
  }

  &.list-view {
    padding: 5px;

    .description {
      display: none;
    }

    .row-buttons {
      visibility: visible;
      display: flex;

      .btn-small {
        line-height: 20px;
        height: 20px;
      }
    }
  }
}

.editable-text-not-editing:hover {
  .row-buttons {
    visibility: visible;
    display: flex;
  }
}

.modal-small {
  max-width: 400px;
}

.mynt-modal {
  width: 70% !important;
  max-width: 850px;

  .image-preview {
    width: 250px;
    height: 150px;
    object-fit: cover;
    border-radius: 15px;
  }

  .error-msg {
    color: red;
    background-color: rgba(255, 0, 0, 0.08);
    padding: 10px;
    border-radius: 10px;
    text-transform: capitalize;
  }

  .modal-banner {
    height: 100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;

    .btn-floating {
      margin: 20px;
    }

    .change-image {
      visibility: hidden;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.594);

      form {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .input-field {
          margin: 0px;

          .upload-btn {
            background-color: #232185;
            color: #fff;
            width: 100%;
            padding: 10px;

            font-size: 1.2rem;
            border: none;
            border-radius: 15px;
            margin-top: 11px;
            margin-bottom: 11px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .modal-edit-item {
    .edit-options-list {
      .option-field {
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        margin: 15px 5px;
      }
    }

    .image-preview {
      width: 250px;
      height: 150px;
      object-fit: cover;
      border-radius: 15px;
    }

    .tabs {
      border-radius: 5px;

      .indicator {
        background-color: #232185;
      }

      .tab {
        a {
          color: #232185;
        }

        a:focus.active {
          background-color: rgb(35 33 133 / 10%);
        }
      }
    }
  }

  .modal-banner:hover {
    .change-image {
      visibility: visible;
    }
  }

  .selection-wrapper {
    width: 100%;

    .item-option {
      width: 100%;

      span {
        width: 100%;
        margin-top: 5px;
        margin-bottom: 5px;

        strong {
          color: #232185;
        }
      }
    }
  }

  .modal-footer {
    border-top: 1px;
    border-bottom: 0px;
    border-right: 0px;
    border-left: 0px;

    border-color: rgb(198, 198, 198);
    border-style: solid;
  }

  .edit-box {
    padding: 15px;

    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 15px;
    margin-top: 15px;
    margin-bottom: 15px;

    input {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .row {
      margin: 10px 0px;
    }

    .image-container {
      width: 250px;

      .remove-img {
        display: none;
        position: absolute;
        top: 4px;
        left: 215px;
        color: white;
        padding: 5px;
        width: 30px;
        height: 30px;
        background-color: rgba(255, 255, 255, 0.412);
        border-radius: 50%;
        text-align: center;

        line-height: 16px;
        font-size: 18px;
        font-weight: 700;
      }

      &:hover {
        .remove-img {
          display: block;
        }
      }
    }
  }

  .time-form {
    max-width: 420px;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 15px;
    padding: 15px;

    table {
      td {
        input {
          padding: 3px;
          border-width: 1px;
          border-style: solid;
          border-radius: 5px;
          height: 1.75rem;
          margin: 0px;
          width: 80px;
          text-align: center;
        }

        padding: 10px;
      }
    }
  }
}

.order-info-row {
  .search {
    border: 5px solid #42a5f5;
    margin-top: 10px;
  }
}

.team-table {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-width: 1px;
  border-style: solid;
  border-color: 1px solid rgba(0, 0, 0, 0.12);

  tr:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }

  .user-details {
    display: flex;
    flex-direction: column;

    .name {
      width: 100%;
    }

    .phone {
      font-size: 10px;
    }
  }
}

.orders-table {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-width: 1px;
  border-style: solid;
  border-color: 1px solid rgba(0, 0, 0, 0.12);

  tr:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }

  .locations {
    i {
      margin-top: 5px;
    }
  }

  .order-id {
    font-size: 12px;
    text-transform: uppercase;
  }

  .customer-details {
    display: flex;
    flex-direction: column;

    .name {
      width: 100%;
    }

    .phone {
      font-size: 10px;
    }
  }

  .order-chip {
    font-size: 12px !important;
    font-weight: 600 !important;
    margin-right: 4px;

    i {
    }
  }
}

.hover-bg:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.pepi-button {
  background-color: #fb867d;
  color: #fff;
  padding: 10px;

  font-size: 1.2rem;
  border: none;
  border-radius: 15px;
  margin-top: 11px;
  margin-bottom: 11px;
  cursor: pointer;
}

.pepi-button:hover {
  background-color: #2f2dad;
}

.pepi-button:focus {
  background-color: #413fad;
}

.dropdown-content {
  .dropdown-title {
    color: black;
    font-weight: 600;
  }
}

.restaurant-chip {
  font-size: 10px !important;
  font-weight: 600 !important;
}

.tos-container {
  .top-part {
  }

  .terms {
    overflow: scroll;
    max-height: 70vh;
  }

  .topbar {
    max-height: 10vh;

    h1 {
      font-size: 3rem;
      margin: 0px;
    }

    img {
      width: 130px;
      height: auto;
      margin-right: 15px;
      margin-left: 15px;
    }

    border-bottom: 1px solid;
    border-color: #fb867d;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    padding: 15px;
  }

  .buttonbar-container {
    .buttonbar {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      button {
        background-color: #fb867d;
      }

      h3 {
        font-size: 2rem;
        margin: 0px;
      }

      background: rgb(85, 91, 95);
      background: linear-gradient(180deg, rgba(85, 91, 95, 0) 0%, rgba(255, 255, 255, 1) 100%);

      padding: 20px;
      border-radius: 15px;
    }
  }

  .terms {
    h2 {
      font-size: 2rem;
    }

    ol,
    p,
    ul {
      color: rgb(140 140 140);
    }
  }
}

.checkbox-mynt[type='checkbox'].filled-in:checked + span:not(.lever):after {
  border: 2px solid #fb867d;
  background-color: #fb867d;
}

.button-mynt {
  background-color: #fb867d !important;
}

.pagination {
  display: flex;
  justify-content: center;
}

.restaurant-container {
  h4 {
    button {
      margin-left: 10px;
    }
  }

  .restaurant-card-wrapper {
    max-width: 600px;
    margin-top: 40px;

    .restaurant-card-content {
      display: flex;
      flex-direction: row;

      .img-column {
        width: 33.33%;

        img {
          width: 100%;
          height: 100%;
          max-height: 130px;
          border-radius: 15px;
          object-fit: cover;
        }
      }

      .details-column {
        width: 66.66%;
        padding-left: 15px;

        h4 {
          margin-top: 0px;
          margin: 0px;
        }

        p {
          margin: 0px;
          margin-top: 5px;
        }

        .description-text {
          font-size: 12px;
        }

        .button-bar {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          .dropdown-content {
            li > a {
              color: #fb867d;
            }
          }
        }
      }

      .details-column-loading {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &.small {
        background-color: rgba(0, 0, 0, 0.042);
        padding: 15px;
        border-radius: 15px;

        .img-column {
          display: none !important;
        }

        .details-column {
          width: 100%;
          padding-left: 0px;

          .description-text {
            display: none;
          }
        }
      }
    }
  }
}

.time-container {
  .edit-time-table {
    max-width: 500px;

    .closed {
      color: #00000061;
    }
  }

  .override-modal {
    max-width: 500px;

    blockquote {
      padding: 15px 10px;
      background-color: #fbee7d20;
    }
  }
}

.restaurant-configuration {
  .image-preview {
    width: 40%;
    height: fit-content;
    overflow: hidden;
  }
}

.branding-container {
  .w-25 {
    width: 25%;
  }

  .w-50 {
    width: 50%;
  }

  .w-100 {
    width: 100%;
  }

  .row-editing {
    .info-text {
      color: rgb(26, 26, 26);
      margin-bottom: 20px;
    }

    .info-box {
      background-color: rgba(255, 214, 67, 0.385);
      padding: 10px;
      border-radius: 15px;
      font-size: 13px;
    }

    .input-field {
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;

      .btn {
        height: auto;
        line-height: 36px;
        border-radius: 10px;
      }
    }

    .ReceiptPreviewWrapper {
      border-width: 1px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.2);
      padding: 15px;
      font-size: 10px;
      max-width: 250px;
      hr {
        border-top: 1px dashed;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .logo {
        filter: contrast(2) grayscale(1);
        width: 60px;
        margin-top: 5px;
        height: auto;
      }

      .centered {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .customerMessage {
          text-align: center;
        }
      }
    }

    b {
      span {
        font-weight: 500;
        font-size: 12px;
      }
    }

    .banner-img {
      max-width: 80%;
      max-height: 300px;
      border-radius: 15px;
    }

    .banner-img:hover {
      border-radius: 0px;
    }

    .selectable-image {
      border-radius: 5px;
      border-style: solid;
      border-width: 1px;
      max-width: 200px;
      height: 375px;
      padding: 5px;
      cursor: pointer;

      .title {
        text-align: center;
      }

      img {
        width: 100%;
      }

      &.selected {
        font-weight: 600;
        border-color: #fb867d;
        background-color: #fb857d1c;
      }

      &.order-screen {
        height: 175px;
        width: 200px;
      }
    }

    .preview-wrapper {
      background-size: cover;
      background-position: center;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      height: 334px;
      width: 188px;

      .logo {
        margin-top: 40px;
        width: 90px;
      }

      .splash {
        width: 140px;
        height: auto;
        max-height: 105px;
      }

      .start {
        width: 85px;
      }
    }

    .order-screen-preview-wrapper {
      font-size: 7.5px;
      text-align: center;
      font-weight: 700;
      height: 135px;

      .col {
        height: 100%;

        p {
          margin-top: 3px;
          font-size: 10px;
        }
      }
    }
  }

  .kiosk-settings-wrapper {
    display: flex;
    flex-direction: column;
    width: 40%;
    .option-row {
      display: flex;
      flex-direction: row;
      background-color: rgba(255, 214, 67, 0.385);
      border-radius: 15px;
      padding: 11px;
      margin-bottom: 5px;
      .option {
      }
      align-items: center;
      .btn-small {
        margin-left: 15px;
      }
    }
  }
}

.toggleable-wrapper {
  .toggleable-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    h3 {
      margin: 0px;
    }
    h4 {
      margin: 0px;
    }
    h3:hover {
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .description {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
}

.stripe-modal {
  .dangerous {
    .input-field {
      background-color: rgba(255, 0, 0, 0.116);
      padding: 5px;
      border-radius: 5px;
    }
  }

  .benefit {
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.056);
  }
}

.error-message {
  margin-top: 15px !important;
  padding: 15px;
  background-color: rgba(255, 0, 0, 0.923);
  font-weight: 700;

  color: white;
  border-radius: 10px;
}

.saving {
  background-color: gray !important;
}

.saving:hover {
  cursor: wait;
}

.menu-selection-wrapper {
  position: sticky;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  bottom: 90px;
  background-color: #fb867de8;
  width: 100%;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  z-index: 20;

  p {
    margin: 0px;
    font-weight: 600;
  }

  .btn-floating i {
    color: rgba(251, 134, 125, 1) !important;
  }

  .count {
    background-color: white;
    color: #fb867d;
    padding: 10px;
    border-radius: 25px;
    margin: 1px;
    margin-right: 8px;
    height: 30px;
    min-width: 30px;
    text-align: center;
    justify-content: center;
    align-items: center;
    line-height: 0.75;
    font-weight: 700;
  }

  .delete-buttons {
    button {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .btn-flat {
    color: white;
  }

  button {
    color: black;
    overflow: hidden;
  }

  &.hide {
    display: none;
  }
}

table {
  &.hide {
    display: none;
  }
}

.edit-row {
  background-color: rgba(0, 0, 0, 0.035);
  border-radius: 15px;

  .edit-form {
    margin: 15px;

    .edit-form-buttons {
      display: flex;
      flex-direction: row;
      button {
        margin-right: 8px;
      }
    }
  }

  .show-code-wrapper {
    margin-left: 15px;
    .qr-code {
      display: flex;
      background: white;
      max-width: fit-content;
      padding: 15px;
    }
  }
}

.btn-fullwidth {
  width: 100%;
}

.multiMenuTable {
  .tr-submenu-editing {
    .title {
      font-weight: 600;
    }
    td {
      padding: 20px;
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.05);

      form {
        button {
          margin-right: 15px !important;
        }
      }
    }
  }

  .tr-title {
    background-color: rgba(0, 0, 0, 0.05);
    font-weight: 600;
  }
}

.create-submenu-form {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 15px;
  border-radius: 10px;
  border-style: solid;
  border-width: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  button {
    margin-top: 15px;
    margin-right: 10px;
  }

  .radio-label {
    margin-top: 5px;
  }

  .radio-p {
    line-height: 20px;
    display: flex;
    align-items: center;
    label {
    }

    input {
      margin-top: 3px;
    }
  }
}

.m-t-5 {
  margin-top: 5px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-0 {
  margin: 0px !important;
}

[type='radio']:not(:checked),
[type='radio']:checked {
  position: relative !important;
  opacity: 1 !important;
  margin-right: 15px;
}

.btn-wrapper {
  button {
    margin-right: 15px;
  }
}

.dropzone {
  border: 1px dashed #ccc;
  padding: 2rem;
  background: #fcfcfc;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-style: italic;
    opacity: 0.5;
  }
}

label {
  input[type='radio'] {
    display: none;
  }
}

.file-list {
  display: flex;
  margin: 10px -10px;
  li {
    // width: calc(100% / 5);
    padding: 0px 10px;
    img,
    video {
      display: block;
      width: 100%;
    }
  }
}

.coupon-codes-container {
  #promoCode {
    text-transform: uppercase;
  }
}

.no-button-bg:focus {
  background-color: transparent !important;
}
