.toggleable-menu {
  display: flex;
  align-items: center;
  position: relative;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 5px;
  border-radius: 8px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  border-style: dashed;
  cursor: pointer;

  .clear-btn,
  .title-btn {
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
  }

  .clear-btn {
    color: #fff;
    margin-right: 8px;
    background-color: rgba(0, 0, 0, 0.26);
    padding: 2px 6px;
    border-radius: 10px;
  }

  .clear-btn:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .title-btn {
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
  }

  .popup {
    position: absolute;
    left: 0;
    top: calc(100% + 8px);
    z-index: 10;
    background-color: #fff;
    border: 1px solid #dfe3e8;
    box-shadow: 0 2px 6px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    overflow: hidden;
    padding: 8px;
    min-width: 200px;

    &::before {
      content: '';
      position: absolute;
      top: -6px;
      left: 12px;
      border-width: 0 6px 6px;
      border-style: solid;
      border-color: transparent transparent #dfe3e8;
    }

    &::after {
      content: '';
      position: absolute;
      top: -5px;
      left: 13px;
      border-width: 0 5px 5px;
      border-style: solid;
      border-color: transparent transparent #fff;
    }

    .search {
      font-size: 10px;
    }

    &.date-filter {
      min-width: 320px;

      .button-row {
        .btn-small {
          margin-right: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
